"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * View Capital API
 * View Capital API
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("./common");
// @ts-ignore
const base_1 = require("./base");
/**
 * DefaultApi - axios parameter creator
 * @export
 */
exports.DefaultApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * get access token for CMS API
         * @param {Login} login get access token for CMS API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken: (login, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'login' is not null or undefined
            common_1.assertParamExists('getAccessToken', 'login', login);
            const localVarPath = `/api/auth/local`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(login, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get dashboard page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardPageDetails: (populate, filtersSlug$eq, locale, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/dashboard-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }
            if (filtersSlug$eq !== undefined) {
                localVarQueryParameter['filters[slug][$eq]'] = filtersSlug$eq;
            }
            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get glass morphism landing page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlassMorphismLandingPageDetails: (populate, filtersSlug$eq, locale, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/glassmorphism-landing-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }
            if (filtersSlug$eq !== undefined) {
                localVarQueryParameter['filters[slug][$eq]'] = filtersSlug$eq;
            }
            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get landing page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingPageDetails: (populate, filtersSlug$eq, locale, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/default-landing-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }
            if (filtersSlug$eq !== undefined) {
                localVarQueryParameter['filters[slug][$eq]'] = filtersSlug$eq;
            }
            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        getNewLandingPageDetails: (populate, locale, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/landing-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }
            // if (filtersSlug$eq !== undefined) {
            //     localVarQueryParameter['filters[slug][$eq]'] = filtersSlug$eq;
            // }
            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        getCounosPageDetails: (populate, locale, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/counos-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }
            // if (filtersSlug$eq !== undefined) {
            //     localVarQueryParameter['filters[slug][$eq]'] = filtersSlug$eq;
            // }
            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        getCustomerPageDetails: (populate, locale, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/customer-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }
            // if (filtersSlug$eq !== undefined) {
            //     localVarQueryParameter['filters[slug][$eq]'] = filtersSlug$eq;
            // }
            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get recommendation page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendationPageDetails: (populate, filtersSlug$eq, locale, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/recommendation-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (populate !== undefined) {
                localVarQueryParameter['populate'] = populate;
            }
            if (filtersSlug$eq !== undefined) {
                localVarQueryParameter['filters[slug][$eq]'] = filtersSlug$eq;
            }
            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
exports.DefaultApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.DefaultApiAxiosParamCreator(configuration);
    return {
        /**
         * get access token for CMS API
         * @param {Login} login get access token for CMS API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken(login, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAccessToken(login, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get dashboard page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardPageDetails(populate, filtersSlug$eq, locale, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDashboardPageDetails(populate, filtersSlug$eq, locale, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get glass morphism landing page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlassMorphismLandingPageDetails(populate, filtersSlug$eq, locale, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getGlassMorphismLandingPageDetails(populate, filtersSlug$eq, locale, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get landing page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingPageDetails(populate, filtersSlug$eq, locale, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLandingPageDetails(populate, filtersSlug$eq, locale, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        getNewLandingPageDetails(populate, locale, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getNewLandingPageDetails(populate, locale, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        getCounosPageDetails(populate, locale, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCounosPageDetails(populate, locale, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        getCustomerPageDetails(populate, locale, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCustomerPageDetails(populate, locale, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get recommendation page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendationPageDetails(populate, filtersSlug$eq, locale, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRecommendationPageDetails(populate, filtersSlug$eq, locale, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
exports.DefaultApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.DefaultApiFp(configuration);
    return {
        /**
         * get access token for CMS API
         * @param {Login} login get access token for CMS API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken(login, options) {
            return localVarFp.getAccessToken(login, options).then((request) => request(axios, basePath));
        },
        /**
         * Get dashboard page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardPageDetails(populate, filtersSlug$eq, locale, options) {
            return localVarFp.getDashboardPageDetails(populate, filtersSlug$eq, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Get glass morphism landing page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlassMorphismLandingPageDetails(populate, filtersSlug$eq, locale, options) {
            return localVarFp.getGlassMorphismLandingPageDetails(populate, filtersSlug$eq, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Get landing page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLandingPageDetails(populate, filtersSlug$eq, locale, options) {
            return localVarFp.getLandingPageDetails(populate, filtersSlug$eq, locale, options).then((request) => request(axios, basePath));
        },
        getNewLandingPageDetails(populate, locale, options) {
            return localVarFp.getNewLandingPageDetails(populate, locale, options).then((request) => request(axios, basePath));
        },
        getCounosPageDetails(populate, locale, options) {
            return localVarFp.getCounosPageDetails(populate, locale, options).then((request) => request(axios, basePath));
        },
        getCustomerPageDetails(populate, locale, options) {
            return localVarFp.getCustomerPageDetails(populate, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Get recommendation page details
         * @param {string} [populate] get all fields
         * @param {string} [filtersSlug$eq] filter by slug
         * @param {string} [locale] language of content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendationPageDetails(populate, filtersSlug$eq, locale, options) {
            return localVarFp.getRecommendationPageDetails(populate, filtersSlug$eq, locale, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
class DefaultApi extends base_1.BaseAPI {
    /**
     * get access token for CMS API
     * @param {Login} login get access token for CMS API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getAccessToken(login, options) {
        return exports.DefaultApiFp(this.configuration).getAccessToken(login, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get dashboard page details
     * @param {string} [populate] get all fields
     * @param {string} [filtersSlug$eq] filter by slug
     * @param {string} [locale] language of content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getDashboardPageDetails(populate, filtersSlug$eq, locale, options) {
        return exports.DefaultApiFp(this.configuration).getDashboardPageDetails(populate, filtersSlug$eq, locale, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get glass morphism landing page details
     * @param {string} [populate] get all fields
     * @param {string} [filtersSlug$eq] filter by slug
     * @param {string} [locale] language of content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getGlassMorphismLandingPageDetails(populate, filtersSlug$eq, locale, options) {
        return exports.DefaultApiFp(this.configuration).getGlassMorphismLandingPageDetails(populate, filtersSlug$eq, locale, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get landing page details
     * @param {string} [populate] get all fields
     * @param {string} [filtersSlug$eq] filter by slug
     * @param {string} [locale] language of content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getLandingPageDetails(populate, filtersSlug$eq, locale, options) {
        return exports.DefaultApiFp(this.configuration).getLandingPageDetails(populate, filtersSlug$eq, locale, options).then((request) => request(this.axios, this.basePath));
    }
    getNewLandingPageDetails(populate, locale, options) {
        return exports.DefaultApiFp(this.configuration).getNewLandingPageDetails(populate, locale, options).then((request) => request(this.axios, this.basePath));
    }
    getCounosPageDetails(populate, locale, options) {
        return exports.DefaultApiFp(this.configuration).getCounosPageDetails(populate, locale, options).then((request) => request(this.axios, this.basePath));
    }
    getCustomerPageDetails(populate, locale, options) {
        return exports.DefaultApiFp(this.configuration).getCustomerPageDetails(populate, locale, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get recommendation page details
     * @param {string} [populate] get all fields
     * @param {string} [filtersSlug$eq] filter by slug
     * @param {string} [locale] language of content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getRecommendationPageDetails(populate, filtersSlug$eq, locale, options) {
        return exports.DefaultApiFp(this.configuration).getRecommendationPageDetails(populate, filtersSlug$eq, locale, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DefaultApi = DefaultApi;
