import { createContext, useReducer } from 'react';
import type { ReactNode } from 'react';
import { cmsApiService } from '../cms-api';
import {
  LandingPage,
  RecommendationPage,
  DashboardPage,
  GlassMorphishmLandingPage,
  NewLandingPage,
  CounosPage,
  CustomerPage
} from 'src/types/landing-page';

interface State {
  landingPageContent: LandingPage | null;
  counosPageContent: CounosPage | null;
  customerPageContent: CustomerPage | null;
  pageContent: NewLandingPage | null;
  recommendationPageContent: RecommendationPage | null;
  dashboardPageContent: DashboardPage | null;
  isContentLoaded: boolean;
  glassMorphismLandingPageContent: GlassMorphishmLandingPage | null;
}

interface StrapiCmsContextValue extends State {
  getLandingPageDetails: (slug: string, language: string) => Promise<void>;
  getNewLandingPageDetails: (slug: string, language: string) => Promise<void>;
  getCounosPageDetails: (slug: string, language: string) => Promise<void>;
  getCustomerPageDetails: (slug: string, language: string) => Promise<void>;
  getRecommendationPageDetails: (
    slug: string,
    language: string
  ) => Promise<void>;
  getDashboardPageDetails: (slug: string, language: string) => Promise<void>;
  getGlassMorphismLandingPageDetails: (
    slug: string,
    language: string
  ) => Promise<void>;
}

interface StrapiCmsProviderProps {
  children: ReactNode;
}

type GetLandingPageDetailsAction = {
  type: 'GETLANDINGPAGEDETAILSACTION';
  payload: {
    landingPageContent: LandingPage;
    isContentLoaded: boolean;
  };
};

type GetNewLandingPageAction = {
  type: 'GETNEWLANDINGPAGEACTION';
  payload: {
    pageContent: NewLandingPage;
    isContentLoaded: boolean;
  };
};

type GetCounosPageAction = {
  type: 'GETCOUNOSPAGEACTION';
  payload: {
    counosPageContent: CounosPage;
    isContentLoaded: boolean;
  };
};

type GetCustomerPageAction = {
  type: 'GETCUSTOMERPAGEACTION';
  payload: {
    customerPageContent: CustomerPage;
    isContentLoaded: boolean;
  };
};

type GetGlassMorphismLandingPageDetailsAction = {
  type: 'GETGlASSMORPHISMLANDINGPAGEDETAILSACTION';
  payload: {
    glassMorphismLandingPageContent: GlassMorphishmLandingPage;
    isContentLoaded: boolean;
  };
};

type GetRecommendationPageDetailsAction = {
  type: 'GETRECOMMENDATIONPAGEDETAILSACTION';
  payload: {
    recommendationPageContent: RecommendationPage;
    isContentLoaded: boolean;
  };
};

type GetDashboardPageDetailsAction = {
  type: 'GETDASHBOARDPAGEDETAILSACTION';
  payload: {
    dashboardPageContent: DashboardPage;
    isContentLoaded: boolean;
  };
};

type UpdateIsContentLoadedAction = {
  type: 'UPDATEISCONTENTLOADEDACTION';
  payload: {
    isContentLoaded: boolean;
  };
};

type Action =
  | GetLandingPageDetailsAction
  | GetNewLandingPageAction
  | GetCounosPageAction
  | GetCustomerPageAction
  | GetRecommendationPageDetailsAction
  | GetDashboardPageDetailsAction
  | UpdateIsContentLoadedAction
  | GetGlassMorphismLandingPageDetailsAction;

const initialState: State = {
  landingPageContent: null,
  pageContent: null,
  counosPageContent: null,
  customerPageContent: null,
  recommendationPageContent: null,
  dashboardPageContent: null,
  glassMorphismLandingPageContent: null,
  isContentLoaded: false,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  GETLANDINGPAGEDETAILSACTION: (
    state: State,
    action: GetLandingPageDetailsAction
  ): State => {
    const { landingPageContent, isContentLoaded } = action.payload;
    return {
      ...state,
      landingPageContent,
      isContentLoaded,
    };
  },
  GETNEWLANDINGPAGEACTION: (
    state: State,
    action: GetNewLandingPageAction
  ): State => {
    const { pageContent, isContentLoaded } = action.payload;
    return {
      ...state,
      pageContent,
      isContentLoaded,
    };
  },
  GETCOUNOSPAGEACTION: (
    state: State,
    action: GetCounosPageAction
  ): State => {
    const { counosPageContent, isContentLoaded } = action.payload;
    return {
      ...state,
      counosPageContent,
      isContentLoaded,
    };
  },
  GETCUSTOMERPAGEACTION: (
    state: State,
    action: GetCustomerPageAction
  ): State => {
    const { customerPageContent, isContentLoaded } = action.payload;
    return {
      ...state,
      customerPageContent,
      isContentLoaded,
    };
  },
  GETGlASSMORPHISMLANDINGPAGEDETAILSACTION: (
    state: State,
    action: GetGlassMorphismLandingPageDetailsAction
  ): State => {
    const { glassMorphismLandingPageContent, isContentLoaded } = action.payload;
    return {
      ...state,
      glassMorphismLandingPageContent,
      isContentLoaded,
    };
  },
  GETRECOMMENDATIONPAGEDETAILSACTION: (
    state: State,
    action: GetRecommendationPageDetailsAction
  ): State => {
    const { recommendationPageContent, isContentLoaded } = action.payload;
    return {
      ...state,
      recommendationPageContent,
      isContentLoaded,
    };
  },
  GETDASHBOARDPAGEDETAILSACTION: (
    state: State,
    action: GetDashboardPageDetailsAction
  ): State => {
    const { dashboardPageContent, isContentLoaded } = action.payload;
    return {
      ...state,
      dashboardPageContent,
      isContentLoaded,
    };
  },
  UPDATEISCONTENTLOADEDACTION: (
    state: State,
    action: UpdateIsContentLoadedAction
  ): State => {
    const { isContentLoaded } = action.payload;
    return {
      ...state,
      isContentLoaded,
    };
  },
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const StrapiCmsContext = createContext<StrapiCmsContextValue>({
  ...initialState,
  getLandingPageDetails: () => Promise.resolve(),
  getNewLandingPageDetails: () => Promise.resolve(),
  getCounosPageDetails: () => Promise.resolve(),
  getCustomerPageDetails: () => Promise.resolve(),
  getRecommendationPageDetails: () => Promise.resolve(),
  getDashboardPageDetails: () => Promise.resolve(),
  getGlassMorphismLandingPageDetails: () => Promise.resolve(),
});

export const StrapiCmsProvider = (props: StrapiCmsProviderProps) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const getLandingPageDetails = async (
    slug: string,
    language: string
  ): Promise<void> => {
    await cmsApiService
      .getLandingPageDetails('deep', slug, language)
      .then((data) => {
        const landingPageContent = data?.data?.data[0]?.attributes;
        landingPageContent.cardUrl = landingPageContent?.Card?.card?.data?.attributes?.url;
        landingPageContent.CobranderMessage.image = landingPageContent?.CobranderMessage?.icon?.data?.attributes?.url;
        dispatch({
          type: 'GETLANDINGPAGEDETAILSACTION',
          payload: {
            landingPageContent,
            isContentLoaded: true,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'UPDATEISCONTENTLOADEDACTION',
          payload: {
            isContentLoaded: true,
          },
        });
      });
  };

  const getNewLandingPageDetails = async (
    slug: string,
    language: string
  ): Promise<void> => {
    await cmsApiService
      .getNewLandingPageDetails('deep', language)
      .then((data) => {
        const benefits = [];
        const actions = [];
        const mobileCards = [];
        const pageContent = data?.data?.data[0]?.attributes;
        pageContent.header.header_logo = pageContent?.header?.header_logo?.data?.attributes?.url;
        pageContent?.MainContent?.mobileCard?.data?.map((item) => mobileCards.push(item?.attributes?.url));
        pageContent.MainContent.mobileCard = mobileCards;
        pageContent.MainContent.mobileScreen = pageContent?.MainContent?.mobileScreen?.data?.attributes?.url;
        pageContent?.benefitsContent?.benefitsImages?.data?.map((item) => benefits.push(item?.attributes?.url));
        pageContent.benefitsContent.benefitsImages = benefits;
        pageContent?.actionContent?.actionImages?.data?.map((item) => actions.push(item?.attributes?.url));
        pageContent.actionContent.actionImages = actions;
        pageContent.footer.footer.header_logo = pageContent?.footer?.footer?.header_logo?.data?.attributes?.url;
        dispatch({
          type: 'GETNEWLANDINGPAGEACTION',
          payload: {
            pageContent,
            isContentLoaded: true,
          },
        });
      })
      .catch(() => {
        console.log('onlyerror');
        dispatch({
          type: 'UPDATEISCONTENTLOADEDACTION',
          payload: {
            isContentLoaded: true,
          },
        });
      });
  };

  const getCounosPageDetails = async (
    slug: string,
    language: string
  ): Promise<void> => {
    await cmsApiService
      .getCounosPageDetails('deep', language)
      .then((data) => {
        const benefits = [];
        const actions = [];
        const mobileCards = [];
        const counosPageContent = data?.data?.data[0]?.attributes;
        counosPageContent.header1.header_logo = counosPageContent?.header1?.header_logo?.data?.attributes?.url;
        counosPageContent?.MainContent1?.mobileCard?.data?.map((item) => mobileCards.push(item?.attributes?.url));
        counosPageContent.MainContent1.mobileCard = mobileCards;
        counosPageContent.MainContent1.mobileScreen = counosPageContent?.MainContent1?.mobileScreen?.data?.attributes?.url;
        counosPageContent?.benefitsContent1?.benefitsImages?.data?.map((item) => benefits.push(item?.attributes?.url));
        counosPageContent.benefitsContent1.benefitsImages = benefits;
        counosPageContent?.actionContent1?.actionImages?.data?.map((item) => actions.push(item?.attributes?.url));
        counosPageContent.actionContent1.actionImages = actions;
        counosPageContent.footer1.footer.header_logo = counosPageContent?.footer1?.footer?.header_logo?.data?.attributes?.url;
        dispatch({
          type: 'GETCOUNOSPAGEACTION',
          payload: {
            counosPageContent,
            isContentLoaded: true,
          },
        });
      })
      .catch(() => {
        console.log('onlyerror');
        dispatch({
          type: 'UPDATEISCONTENTLOADEDACTION',
          payload: {
            isContentLoaded: true,
          },
        });
      });
  };

  const getCustomerPageDetails = async (
    slug: string,
    language: string
  ): Promise<void> => {
    await cmsApiService
      .getCustomerPageDetails('deep', language)
      .then((data) => {
        const benefits = [];
        const actions = [];
        const mobileCards = [];
        const customerPageContent = data?.data?.data[0]?.attributes;
        customerPageContent.header2.header_logo = customerPageContent?.header2?.header_logo?.data?.attributes?.url;
        customerPageContent?.MainContent2?.mobileCard?.data?.map((item) => mobileCards.push(item?.attributes?.url));
        customerPageContent.MainContent2.mobileCard = mobileCards;
        customerPageContent.MainContent2.mobileScreen = customerPageContent?.MainContent2?.mobileScreen?.data?.attributes?.url;
        customerPageContent?.benefitsContent2?.benefitsImages?.data?.map((item) => benefits.push(item?.attributes?.url));
        customerPageContent.benefitsContent2.benefitsImages = benefits;
        customerPageContent?.actionContent2?.actionImages?.data?.map((item) => actions.push(item?.attributes?.url));
        customerPageContent.actionContent2.actionImages = actions;
        customerPageContent.footer2.footer.header_logo = customerPageContent?.footer2?.footer?.header_logo?.data?.attributes?.url;
        dispatch({
          type: 'GETCUSTOMERPAGEACTION',
          payload: {
            customerPageContent,
            isContentLoaded: true,
          },
        });
      })
      .catch(() => {
        console.log('onlyerror');
        dispatch({
          type: 'UPDATEISCONTENTLOADEDACTION',
          payload: {
            isContentLoaded: true,
          },
        });
      });
  };

  const getGlassMorphismLandingPageDetails = async (
    slug: string,
    language: string
  ): Promise<void> => {
    await cmsApiService
      .getGlassMorphismLandingPageDetails('deep', slug, language)
      .then((data) => {
        const contentData = data?.data?.data[0]?.attributes;
        const content: any = {};
        const bannerContent = contentData?.Banner;
        content.banner = {
          heading: bannerContent?.heading,
          subHeading: bannerContent?.subheading,
          cardImage: bannerContent?.card?.data?.attributes?.url,
        };

        // Journey Component Content
        const journeyContent = contentData?.Journey;
        content.journey = {
          heading: journeyContent?.heading,
          title: journeyContent?.title,
          description1: journeyContent?.description1,
          description2: journeyContent?.description2,
        };
        content.journey.journeyProcess = [];
        journeyContent.JourneyProcess?.forEach((item: any) => {
          content.journey.journeyProcess.push({
            id: item?.id,
            step: item?.Step1,
            image: item?.Image?.data?.attributes?.url,
          });
        });

        // Benefits Component content
        const benefitsIntroductionData = contentData?.BenefitsIntroduction;
        content.benefitsIntroduction = {
          title: benefitsIntroductionData?.title,
          heading: benefitsIntroductionData?.heading,
          subHeading: benefitsIntroductionData?.subheading,
        };
        content.benefitDetails = [];
        contentData?.BenefitsDetails?.forEach((item: any) => {
          content.benefitDetails.push({
            id: item?.id,
            benefit: item?.benefit,
            description: item?.description,
            cardImage: item?.card?.data?.attributes?.url,
          });
        });

        // Branding component content
        const brandingContent = contentData?.Branding;
        content.branding = {
          title: brandingContent?.title,
          heading: brandingContent?.heading,
          subHeading: brandingContent?.subheading,
          content: brandingContent?.content,
          brandImage: brandingContent?.brands?.data?.attributes?.url,
        };

        // Good to know component content
        const goodToKnowIntroductionContent = contentData?.GoodToKnowIntroduction;
        content.goodToKnowIntroduction = {
          title: goodToKnowIntroductionContent?.title,
          heading: goodToKnowIntroductionContent?.heading,
          subHeading: goodToKnowIntroductionContent?.subheading,
        };
        content.goodToKnowDetails = [];
        contentData?.GoodToKnowDetails?.forEach((item: any) => {
          content.goodToKnowDetails.push({
            id: item?.id,
            question: item?.question,
            answer: item?.answer,
          });
        });

        // social media content
        content.socialMedia = [];
        contentData?.SocialMedia?.forEach((item: any) => {
          content.socialMedia.push({
            id: item?.id,
            name: item?.name,
            url: item?.url,
            icon: item?.icon?.data?.attributes?.url,
          });
        });

        const glassMorphismLandingPageContent = content;

        dispatch({
          type: 'GETGlASSMORPHISMLANDINGPAGEDETAILSACTION',
          payload: {
            glassMorphismLandingPageContent,
            isContentLoaded: true,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'UPDATEISCONTENTLOADEDACTION',
          payload: {
            isContentLoaded: true,
          },
        });
      });
  };

  const getRecommendationPageDetails = async (
    slug: string,
    language: string
  ): Promise<void> => {
    await cmsApiService
      .getRecommendationPageDetails('deep', slug, language)
      .then((data) => {
        const recommendationPageContent = data?.data?.data[0]?.attributes;
        recommendationPageContent.imageUrl = recommendationPageContent.ImageBlock.image.data[0].attributes.url;
        dispatch({
          type: 'GETRECOMMENDATIONPAGEDETAILSACTION',
          payload: {
            recommendationPageContent,
            isContentLoaded: true,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'UPDATEISCONTENTLOADEDACTION',
          payload: {
            isContentLoaded: true,
          },
        });
      });
  };

  const getDashboardPageDetails = async (
    slug: string,
    language: string
  ): Promise<void> => {
    await cmsApiService
      .getDashboardPageDetails('deep', slug, language)
      .then((data) => {
        const dashboardPageContent = data?.data?.data[0]?.attributes;
        dispatch({
          type: 'GETDASHBOARDPAGEDETAILSACTION',
          payload: {
            dashboardPageContent,
            isContentLoaded: true,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'UPDATEISCONTENTLOADEDACTION',
          payload: {
            isContentLoaded: true,
          },
        });
      });
  };

  return (
    <StrapiCmsContext.Provider
      value={{
        ...state,
        getLandingPageDetails,
        getNewLandingPageDetails,
        getCounosPageDetails,
        getCustomerPageDetails,
        getRecommendationPageDetails,
        getGlassMorphismLandingPageDetails,
        getDashboardPageDetails,
      }}
    >
      {children}
    </StrapiCmsContext.Provider>
  );
};

export default StrapiCmsContext;
