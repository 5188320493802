import { FC, useEffect, useState } from 'react';
import {
  Box,
  experimentalStyled,
} from '@material-ui/core';
import useStrapiCms from 'src/hooks/useStrapiCms';
import { useTranslation } from 'react-i18next';
import '../landingStyle/style.css';
import { STRAPI_URL } from 'src/config';
import Accordion from 'react-bootstrap/Accordion';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setLanguage } from 'src/api';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Static from '../landingStyle/images/static.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import $ from 'jquery';

AOS.init({
  disable: () => {
    const maxWidth = 767;
    return window.innerWidth > maxWidth;
  },
});

const CircularProgressWrapper = experimentalStyled(CircularProgress)(
  {
    position: 'absolute',
    height: '60px !important',
    width: '60px !important',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  }
);

const CustomerPage: FC = () => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(true);
  const [isTransform, setIsTransform] = useState(false);
  const [drawer, setDrawer] = useState(0);
  const { customerPageContent, getCustomerPageDetails } = useStrapiCms();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:767px)');

  const handleChangeLanguageDe = async () => {
    i18n.changeLanguage('de');
    setLanguage('de');
    await getCustomerPageDetails('', 'de');
  };

  const handleChangeLanguageIt = async () => {
    i18n.changeLanguage('it');
    setLanguage('it');
    await getCustomerPageDetails('', 'it');
  };

  const handleChangeLanguageFr = async () => {
    i18n.changeLanguage('fr');
    setLanguage('fr');
    await getCustomerPageDetails('', 'fr');
  };

  const landingPageData = async () => {
    i18n.changeLanguage('en');
    setLanguage('en');
    await getCustomerPageDetails('', 'en');
  };

  useEffect(() => {
    if (pathname.split('/')[2] === 'english' || pathname.split('/')[2] === 'german' || pathname.split('/')[2] === 'italian' || pathname.split('/')[2] === 'french') {
      if (pathname.split('/')[2] === 'german') {
        handleChangeLanguageDe();
      } else if (pathname.split('/')[2] === 'italian') {
        handleChangeLanguageIt();
      } else if (pathname.split('/')[2] === 'french') {
        handleChangeLanguageFr();
      } else if (pathname.split('/')[2] === 'english') {
        landingPageData();
      }
    }
  }, [pathname.split('/')[2]]);

  useEffect(() => {
    if (customerPageContent) {
      setOpen(false);
    }
  }, [customerPageContent]);

  const cssChangeId = (index: any) => {
    if (index === 0) {
      return 'card-1';
    }
    if (index === 1) {
      return 'card-2';
    }
    if (index === 2) {
      return 'card-3';
    }
    if (index === 3) {
      return 'card-4';
    }
    return null;
  };

  const cssChangeImage = (index: any) => {
    if (index === 0) {
      return 'first-img';
    }
    if (index === 1) {
      return 'second-img';
    }
    if (index === 2) {
      return 'third-img';
    }
    if (index === 3) {
      return 'forth-img';
    }
    return null;
  };

  const handleSubmit = (index: any) => {
    window.open(index, '_blank');
  };

  const handleChecked = (event:any) => {
    if (event === pathname.split('/')[2]) {
      return true;
    }
    return false;
  };

  const onHover = (index) => {
    if (index === 0) {
      const target1 = document.querySelector('#card-1');
      const firstImg1 = document.querySelector('.first-img');
      target1.addEventListener(
        'mouseover',
        () => {
          firstImg1.classList.add('first-hoverimg');
          // if (pathname.split('/')[2] === 'english') {
          // } else {
          //   firstImg1.classList.add('forth-hoverimg');
          // }
        },
      );
      // target1.addEventListener(
      //   'mouseleave',
      //   () => firstImg1.classList.remove('first-hoverimg'),
      // );
    }
    if (index === 1) {
      const target2 = document.querySelector('#card-2');
      const secImg = document.querySelector('.second-img');
      target2.addEventListener(
        'mouseover',
        () => {
          secImg.classList.add('second-hoverimg');
          // if (pathname.split('/')[2] === 'english') {
          // } else {
          //   secImg.classList.add('third-hoverimg');
          // }
        }
      );
      // target2.addEventListener(
      //   'mouseleave',
      //   () => secImg.classList.remove('second-hoverimg'),
      // );
    }
    if (index === 2) {
      const target3 = document.querySelector('#card-3');
      const thiImg = document.querySelector('.third-img');
      target3.addEventListener(
        'mouseover',
        () => {
          thiImg.classList.add('third-hoverimg');
          // if (pathname.split('/')[2] === 'english') {
          // } else {
          //   thiImg.classList.add('second-hoverimg');
          // }
        }
      );
      // target3.addEventListener(
      //   'mouseleave',
      //   () => thiImg.classList.remove('third-hoverimg'),
      // );
    }
    if (index === 3) {
      const target4 = document.querySelector('#card-4');
      const fouImg = document.querySelector('.forth-img');
      target4.addEventListener(
        'mouseover',
        () => {
          fouImg.classList.add('forth-hoverimg');
          // if (pathname.split('/')[2] === 'english') {
          // } else {
          //   fouImg.classList.add('first-hoverimg');
          // }
        },
      );
      // target4.addEventListener(
      //   'mouseleave',
      //   () => fouImg.classList.remove('forth-hoverimg'),
      // );
    }
    return null;
  };
  const HeaderButton = styled.button`
  background-color: ${customerPageContent?.header2?.button_color} !important;
  border: 1px solid ${customerPageContent?.header2?.button_color} !important;
  &:hover {
    background-color: transparent !important;
    color: ${customerPageContent?.header2?.button_color} !important;
  }
`;
  const MaincontentButton = styled.button`
    background-color: ${customerPageContent?.MainContent2?.button_color} !important;
    border: 1px solid ${customerPageContent?.MainContent2?.button_color} !important;
    &:hover {
      background-color: transparent !important;
      color: ${customerPageContent?.MainContent2?.button_color} !important;
    }
  `;
  const ActioncontentButton = styled.button`
    background-color: ${customerPageContent?.actionContent2?.button_color} !important;
    border: 1px solid ${customerPageContent?.actionContent2?.button_color} !important;
    &:hover {
      background-color: transparent !important;
      color: ${customerPageContent?.actionContent2?.button_color} !important;
    }
  `;
  const cssClass = () => {
    if (pathname.split('/')[2] === 'english') {
      return 'english';
    }
    if (pathname.split('/')[2] === 'german') {
      return 'german';
    }
    if (pathname.split('/')[2] === 'italian') {
      return 'italian';
    }
    if (pathname.split('/')[2] === 'french') {
      return 'french';
    }
    return '';
  };
  const spaceCss = (index:any) => {
    if (index === 0) {
      return 'col-xl-4 col-lg-4 col-md-4 d-xl-block d-lg-block d-md-none d-sm-none d-none col-sm-12 col-12';
    }
    if (index === 1) {
      return 'col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-xl-block d-lg-block d-md-none d-sm-none d-none order-lg-3 order-xl-3';
    }
    if (index === 2) {
      return 'col-xl-4 col-lg-4 col-md-4 d-xl-block d-lg-block d-md-none d-sm-none d-none col-sm-12 col-12';
    }
    if (index === 3) {
      return 'col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-xl-block d-lg-block d-md-none d-sm-none d-none order-1 order-sm-3 order-md-3 order-lg-3 order-xl-3';
    }
    return '';
  };
  const spaceCss1 = (index:any) => {
    if (index === 0) {
      return 'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12';
    }
    if (index === 1) {
      return 'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 order-1 order-sm-1 order-md-1 order-lg-2 order-xl-2';
    }
    if (index === 2) {
      return 'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12';
    }
    if (index === 3) {
      return 'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 order-1 order-sm-1 order-md-1 order-lg-2 order-xl-2';
    }
    return '';
  };
  const spaceCss2 = (index:any) => {
    if (index === 0) {
      return 'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12';
    }
    if (index === 1) {
      return 'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 order-2 order-sm-2 order-md-2 order-lg-1 order-xl-1';
    }
    if (index === 2) {
      return 'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12';
    }
    if (index === 3) {
      return 'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 order-2 order-sm-2 order-md-2 order-lg-1 order-xl-1';
    }
    return '';
  };
  $(window).on('scroll', () => {
    if ($('.the-benefits').offset()?.top - 200 > $(window)?.scrollTop() || $('.good-to-know').offset()?.top - 300 < $(window)?.scrollTop()) {
      // if (pathname.split('/')[2] === 'english') {
      $('.first-img').removeClass('first-hoverimg');
      $('.second-img').removeClass('second-hoverimg');
      $('.third-img').removeClass('third-hoverimg');
      $('.forth-img').removeClass('forth-hoverimg');
      // } else {
      //   $('.first-img').removeClass('forth-hoverimg');
      //   $('.second-img').removeClass('third-hoverimg');
      //   $('.third-img').removeClass('second-hoverimg');
      //   $('.forth-img').removeClass('first-hoverimg');
      // }
    }
  });
  const handleChange = () => {
    setIsTransform(!isTransform);
  };
  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      {
        open ? (
          <CircularProgressWrapper />
        ) : (
          <div>
            <div className="wrapper" style={{ overflow: 'auto' }}>
              <header className="header" style={{ backgroundColor: customerPageContent?.header2?.backgroundColor }}>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="menu-bar">
                        <a className="brand-logo" href={customerPageContent?.header2?.logolink} target="_blank" rel="noreferrer">
                          <img
                            src={`${STRAPI_URL}${customerPageContent?.header2?.header_logo}`}
                            alt="lci_primary_logo"
                          />
                        </a>
                        <HeaderButton type="button" className="apply-card-btn" onClick={() => handleSubmit(customerPageContent?.header2?.header_button_link)}>
                          {customerPageContent?.header2?.button_name}
                        </HeaderButton>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <section className="hero-section" style={{ background: customerPageContent?.MainContent2?.backgroundColor }}>
                <label className="hero-switch" htmlFor="checkbox">
                  <input
                    type="checkbox"
                    id="checkbox"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        document.querySelector('.cc-card-img-wrap').classList.toggle('flipped');
                      } else if (e.target.checked === false) {
                        document.querySelector('.cc-card-img-wrap').classList.toggle('flipped');
                      }
                    }}
                  />
                  <div className="hero-switch-slider round" />
                </label>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="hero-data section-text">
                        <h1 className="section-title">
                          {customerPageContent?.MainContent2?.params?.title}
                        </h1>
                        <p>
                          {customerPageContent?.MainContent2?.params?.subtitle}
                        </p>
                        <MaincontentButton type="button" className="apply-cc-btn" onClick={() => handleSubmit(customerPageContent?.MainContent2?.content_link)}>
                          {customerPageContent?.MainContent2?.button_name}
                        </MaincontentButton>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="hero-img">
                        <img
                          src={`${STRAPI_URL}${customerPageContent?.MainContent2?.mobileScreen}`}
                          alt="iphoneframeonly"
                          className="iphone-frame"
                        />
                        <div className="cc-card-img-wrap">
                          <img
                            src={`${STRAPI_URL}${customerPageContent?.MainContent2?.mobileCard[0]}`}
                            alt="lionsclub-card-one"
                            className="cc-card-one"
                          />
                          <img
                            src={`${STRAPI_URL}${customerPageContent?.MainContent2?.mobileCard[1]}`}
                            alt="lionsclub-card-two"
                            className="cc-card-two"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="your-journey" style={{ background: customerPageContent?.JoinContent2?.backgroundColor, display: customerPageContent?.JoinContent2?.displaySection === 'true' ? 'none' : '' }}>
                {/* <button type="button" className="blod-switch">
                  <span className="blod-switch-slider round" />
                </button> */}
                <label className="blod-switch" htmlFor="checkboxTwo">
                  <input
                    type="checkbox"
                    id="checkboxTwo"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        document.querySelector('.cc-card-img-wrap').classList.toggle('flipped');
                      } else if (e.target.checked === false) {
                        document.querySelector('.cc-card-img-wrap').classList.toggle('flipped');
                      }
                    }}
                  />
                  <div className="blod-switch-slider round" />
                </label>
                <div className="container hero-switch-css">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="yj-data section-text">
                        <h2 className="section-title">{customerPageContent?.JoinContent2?.params?.title}</h2>
                        <p>
                          {customerPageContent?.JoinContent2?.params?.subtitle}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <ul className="yj-list">
                        {customerPageContent?.JoinContent2?.joinlist.map((item) => (
                          <li className="yj-item">
                            <h3>{item?.list}</h3>
                            <p>learn more</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>

              <section className={`the-benefits ${cssClass()}`} style={{ background: customerPageContent?.benefitsContent2?.backgroundColor }}>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="the-benefits-title section-text">
                        <h2 className="section-title">{customerPageContent?.benefitsContent2?.params?.title}</h2>
                        <p className="the-benefits-content">
                          {customerPageContent?.benefitsContent2?.params?.subtitle}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container benefits-direction">
                  {customerPageContent?.benefitsContent2?.benefitsText.map((item, index) => (
                    <>
                      <div className="row benefits-list">
                        {
                          index % 2 === 0 && (
                            <>
                              <div className={spaceCss(index)}>{}</div>
                              <div className={spaceCss1(index)}>
                                <div className="card-img-wrap">
                                  <div className={cssChangeImage(index)} data-aos={matches ? 'fade-up' : ''} data-aos-duration="1000">
                                    <img
                                      src={`${STRAPI_URL}${customerPageContent?.benefitsContent2?.benefitsImages[index]}`}
                                      alt="card-top"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={spaceCss2(index)} onMouseOver={() => onHover(index)} onFocus={() => onHover(index)}>
                                <div className="card-benefits" id={cssChangeId(index)}>
                                  <div className="gold-content" data-aos={matches ? 'fade-up' : ''} data-aos-duration="1500">
                                    <h4>{item?.title}</h4>
                                    <p>{item?.subtitle}</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        }
                        {
                          index % 2 !== 0 && (
                            <>
                              <div className={spaceCss2(index)}>
                                <div className="card-benefits" id={cssChangeId(index)} onMouseOver={() => onHover(index)} onFocus={() => onHover(index)}>
                                  <div className="gold-content" data-aos={matches ? 'fade-up' : ''} data-aos-duration="1500">
                                    <h4>{item?.title}</h4>
                                    <p>{item?.subtitle}</p>
                                  </div>
                                </div>
                              </div>
                              <div className={spaceCss1(index)}>
                                <div className="card-img-wrap">
                                  <div className={cssChangeImage(index)} data-aos={matches ? 'fade-up' : ''} data-aos-duration="1000">
                                    <img
                                      src={`${STRAPI_URL}${customerPageContent?.benefitsContent2?.benefitsImages[index]}`}
                                      alt="card-top"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={spaceCss(index)}>{}</div>
                            </>
                          )
                        }
                      </div>
                    </>
                  ))}
                </div>
              </section>

              <section className="good-to-know" style={{ background: customerPageContent?.FaqContent2?.backgroundColor }}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="good-know-data section-text">
                        <h2 className="section-title2">{customerPageContent?.FaqContent2?.params?.title}</h2>
                        <p>
                          {customerPageContent?.FaqContent2?.params?.subtitle}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-7">
                      {customerPageContent?.FaqContent2?.FaqQA.map((item, index) => (
                        <Accordion className="gk-accordion" activeKey={`${drawer}`} onSelect={(eventkey: any) => { setDrawer(eventkey); }}>
                          <Accordion.Item eventKey={`${index}`}>
                            <Accordion.Header>{item.title}</Accordion.Header>
                            <Accordion.Body>
                              {item.subtitle}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ))}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="call-action-wrap" style={{ background: customerPageContent?.actionContent2?.backgroundColor }}>
                        <div className="row">
                          <div className="col-xl-6 col-lg-9 col-md-8 col-sm-12 col-12 mx-auto">
                            <div className="flip-card call-action-card">
                              <div className="flip-card-inner" onClick={() => handleChange()} style={{ transform: isTransform ? 'rotateY(180deg)' : '' }} aria-hidden="true">
                                <img src={Static} alt="lionsclub-card-two" />
                                <div className="flip-card-front">
                                  <img
                                    src={`${STRAPI_URL}${customerPageContent?.actionContent2?.actionImages[0]}`}
                                    alt="lionsclub-card-two"
                                  />
                                </div>
                                <div className="flip-card-back">
                                  <img
                                    src={`${STRAPI_URL}${customerPageContent?.actionContent2?.actionImages[1]}`}
                                    alt="lionsclub-card-one"
                                    className="club-card-one"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-9 col-md-8 col-sm-12 col-12 mx-auto">
                            <div className="call-action-datat section-text">
                              <h2 className="section-title">{customerPageContent?.actionContent2?.params?.title}</h2>
                              <p>
                                {customerPageContent?.actionContent2?.params?.subtitle}
                              </p>
                              <ActioncontentButton type="button" className="apply-crad-btn" onClick={() => handleSubmit(customerPageContent?.actionContent2?.action_button_link)}>{customerPageContent?.actionContent2?.button_name}</ActioncontentButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-12">
                      <div className="call-action-wrap" style={{ background: customerPageContent?.actionContent2?.backgroundColor }}>
                        <div className="flip-card call-action-card">
                          <div className="flip-card-inner">
                            <div className="flip-card-front">
                              <img
                                src={`${STRAPI_URL}${customerPageContent?.actionContent2?.actionImages[0]}`}
                                alt="lionsclub-card-two"
                              />
                            </div>
                            <div className="flip-card-back">
                              <img
                                src={`${STRAPI_URL}${customerPageContent?.actionContent2?.actionImages[1]}`}
                                alt="lionsclub-card-one"
                                className="club-card-one"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="call-action-datat section-text">
                          <h2 className="section-title">{customerPageContent?.actionContent2?.params?.title}</h2>
                          <p className="sectionp">
                            {customerPageContent?.actionContent2?.params?.subtitle}
                          </p>
                          <ActioncontentButton type="button" className="apply-crad-btn" onClick={() => handleSubmit(customerPageContent?.actionContent2?.action_button_link)}>{customerPageContent?.actionContent2?.button_name}</ActioncontentButton>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </section>

              <footer className="footer" style={{ background: customerPageContent?.footer2?.backgroundColor }}>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="footer-content">
                        <a className="f-logo" href={customerPageContent?.header2?.logolink} target="_blank" rel="noreferrer">
                          <img
                            src={`${STRAPI_URL}${customerPageContent?.footer2?.footer?.header_logo}`}
                            alt="lci_primary-flogo"
                          />
                        </a>
                        <p>{customerPageContent?.footer2?.footer_address}</p>
                        <div className="tc">
                          <ul className="f-list">
                            <li className="f-link">
                              <a href={customerPageContent?.footer2?.footer_term_link} target="_blank" rel="noreferrer">{customerPageContent?.footer2?.footer_term_text}</a>
                            </li>
                            <li className="f-link">
                              <a href={customerPageContent?.footer2?.footer_legal_link} target="_blank" rel="noreferrer">{customerPageContent?.footer2?.footer_legal_text}</a>
                            </li>
                          </ul>
                          <ul className="f-lang f-list">
                            <li className="f-link">
                              <button type="button" className="languagebutton" disabled={handleChecked('english')} onClick={() => { navigate('/customer-landingpage/english'); setOpen(true); setDrawer(0); setIsTransform(false); }}>en</button>
                            </li>
                            <li className="f-link">
                              <button type="button" className="languagebutton" disabled={handleChecked('german')} onClick={() => { navigate('/customer-landingpage/german'); setOpen(true); setDrawer(0); setIsTransform(false); }}>de</button>
                            </li>
                            <li className="f-link">
                              <button type="button" className="languagebutton" disabled={handleChecked('italian')} onClick={() => { navigate('/customer-landingpage/italian'); setOpen(true); setDrawer(0); setIsTransform(false); }}>it</button>
                            </li>
                            <li className="f-link">
                              <button type="button" className="languagebutton" disabled={handleChecked('french')} onClick={() => { navigate('/customer-landingpage/french'); setOpen(true); setDrawer(0); setIsTransform(false); }}>fr</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        )
      }
    </Box>
  );
};

export default CustomerPage;
